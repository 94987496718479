<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 18:00:32
 * @LastEditTime : 2022-11-02 10:43:59
 * @LastEditors  : BigBigger
-->
<template>
	<div class="app-page-2">
		<div class="app-content">
			<div class="container">
				<!-- banner -->
				<div class="banner">
					<el-carousel trigger="click" height="260px">
						<el-carousel-item
							v-for="(item, index) of bannerList"
							:key="index"
						>
							<img
								@click="bannerClick(item)"
								:key="index"
								:src="item.webImg"
								:alt="item.carouselName"
							/>
						</el-carousel-item>
					</el-carousel>
				</div>
				<!-- 课程组分类 -->
				<div class="type">
					<div class="type-list">
						<p
							class="type-item"
							:class="{
								active:
									activedCourseGroupCategroy.value ===
									item.value,
							}"
							@click="courseGroupCategroyClick(item)"
							v-for="(item, index) of courseGroupCategroyList"
							:key="index"
							:data-value="item.value"
						>
							{{ item.code }}
						</p>
					</div>
					<div
						class="type-list-2"
						v-if="courseGroupSecondCategroyList.length"
					>
						<p
							class="type-item"
							:class="{ active: !secondCategroy }"
							@click="courseGroupSecondCategroyClick()"
						>
							全部课程
						</p>
						<p
							class="type-item"
							:class="{ active: secondCategroy === item.value }"
							@click="courseGroupSecondCategroyClick(item.value)"
							v-for="(
								item, index
							) of courseGroupSecondCategroyList"
							:key="index"
							:data-value="item.value"
						>
							{{ item.code }}
						</p>
					</div>
				</div>
				<div
					class="course-group-list"
					v-loading="loading"
					element-loading-text="拼命加载中"
				>
					<div style="width: 1240px; margin-left: -20px">
						<el-row>
							<el-col
								:span="8"
								v-for="(item, index) of courseGroupList"
								:key="index"
							>
								<CourseGroupCard
									@click="courseGroupCardClick"
									style="margin: 0 auto 30px"
									:info="item"
								/>
							</el-col>
						</el-row>
					</div>
					<div
						class="empty"
						v-if="courseGroupList && courseGroupList.length === 0"
					>
						<img
							src="@/assets/images/mine/empty.png"
							alt="暂无数据"
						/>
					</div>
					<div
						class="page-left"
						v-if="!courseGroupList || courseGroupList.length !== 0"
					>
						<el-pagination
							background
							layout="prev, pager, next, total"
							@current-change="currentPageChange"
							:page-size="limit"
							:total="total"
						>
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
let courseGroupCategroyAll = { code: '全部课程', value: '' };
import CourseGroupCard from '@/components/courseGroup/CourseGroupCard';
import {
	getBanner,
	getCourseGroup,
	getCourseGroupCategroy,
	getCourseGroupSecondCategroy,
} from '@/api/api';
import { checkOrderStatus } from '@/views/common/check';
export default {
	// async asyncData(context) {
	//   let categoryName = context.query.categoryName || ''
	//   // 获取轮播图
	//   let banner = await getBanner({})

	//   // 获取课程组分类
	//   let courseGroupCategroy = await getCourseGroupCategroy({})
	//   // 分页获取课程组
	//   let courseGroup = await getCourseGroup({
	//     categoryName: categoryName,
	//     page: 1,
	//     limit: 6
	//   })
	//   return {
	//     bannerList: banner.data,
	//     courseGroupCategroyList: [courseGroupCategroyAll, ...courseGroupCategroy.data],
	//     courseGroupList: courseGroup.data,
	//     // activedCourseGroupCategroy: categoryName || courseGroupCategroyAll,
	//     total:courseGroup.total,
	//   }

	// },
	data() {
		return {
			activedCourseGroupCategroy: courseGroupCategroyAll, // 选中的课程组分类
			categoryName: '',
			secondCategroy: undefined,
			page: 1,
			limit: 6,
			total: 0,
			courseGroupList: [], // 课程组列表
			loading: false,
			bannerList: [],
			courseGroupCategroyList: [],
			courseGroupSecondCategroyList: [],
			courseGroupList: [],
		};
	},
	components: {
		CourseGroupCard,
	},
	mounted() {
    checkOrderStatus(this);
		let categoryName =
			this.$route.query.categoryName ||
			sessionStorage.getItem('categoryName') ||
			'';
		this.categoryName = categoryName;
		if (categoryName) {
			this.activedCourseGroupCategroy = {
				code: categoryName,
				value: categoryName,
			};
		} else {
			this.activedCourseGroupCategroy = courseGroupCategroyAll;
		}
		this.getBanner();
		this.getCourseGroupCategroy();
		this.getCourseGroup();
	},
	methods: {
		async getCourseGroupCategroy() {
			let response = await getCourseGroupCategroy();
			if (+response.returnCode !== 10001) {
				return false;
			}
			const data = this.$store.state.city ? response.data.filter((i) => i.city == this.$store.state.city) : response.data;
			this.courseGroupCategroyList = sessionStorage.getItem(
				'categoryName',
			)
				? data.filter((i) => i.value === this.categoryName)
				: [courseGroupCategroyAll, ...data];
		},
		async getBanner() {
			let response = await getBanner();
			if (+response.returnCode !== 10001) {
				return false;
			}
			this.bannerList = response.data;
		},
		currentPageChange(currentPage) {
			this.page = currentPage;
			this.getCourseGroup();
		},
		// 课程组卡片点击
		courseGroupCardClick(item) {
			// this.$router.push({path: `/course/${item.id}`})
			this.$router.push({ path: `/course/${item.id}` });
		},
		// 获取课程组
		async getCourseGroup() {
			const params = {
				categoryName: this.categoryName,
				secondCategoryId: this.secondCategroy,
				page: this.page,
				limit: this.limit,
			};
			this.loading = true;
			let response = await getCourseGroup(params);
			this.loading = false;
			if (+response.returnCode !== 10001) {
				return false;
			}
			this.total = response.total;
			this.courseGroupList = response.data;
		},
		courseGroupCategroyClick(item) {
			this.page = 1;
			this.secondCategroy = undefined;
			this.courseGroupSecondCategroyList = [];
			this.activedCourseGroupCategroy = item;
			this.categoryName = item.value;
			this.getCourseGroupSecondCategroy();
			this.getCourseGroup();
		},
		courseGroupSecondCategroyClick(value) {
			this.page = 1;
			this.secondCategroy = value;
			this.getCourseGroup();
		},
		bannerClick(item) {
			if (item.type === 1) {
				window.open(item.val);
			} else if (item.type === 2) {
				this.$router.push({ path: `/course/${item.val}` });
			} else {
				console.warn('暂无跳转');
			}
		},
		getCourseGroupSecondCategroy() {
			if (this.categoryName)
				getCourseGroupSecondCategroy({
					page: 1,
					limit: 999,
					categoryId: this.categoryName,
				}).then((res) => {
					this.courseGroupSecondCategroyList = res.data || [];
				});
		},
	},
};
</script>

<style lang="less" scoped>
.container {
	padding: 40px 0;
}
.banner {
	width: 1200px;
	background: #999;
	height: 260px;
	border-radius: 12px;
	overflow: hidden;
	margin-bottom: 43px;
	box-shadow: 0 5px 12px 0 rgba(7, 17, 27, 0.2);
	img {
		width: 100%;
	}
}
.type {
	// background: #FFFFFF;
	// border: 1px solid #E6E6E6;
	border-radius: 12px;
	// padding: 20px;
	margin-bottom: 35px;
	line-height: 30px;
}
.type-list {
	display: flex;
	flex-wrap: wrap;
	.type-item {
		display: block;
		padding: 0 14px;
		border-radius: 6px;
		font-size: 16px;
		// font-weight: bold;
		color: #5b6b73;
		cursor: pointer;
		user-select: none;
		margin-right: 30px;
		&:hover {
			color: #000;
			background: #f1f1f1;
		}
		&.active {
			background: #ff3f47;
			color: #fff;
			// font-weight: bold;
		}
	}
}
.type-list-2 {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid #e6e6e6;
	margin-top: 18px;
	padding-top: 16px;
	.type-item {
		display: block;
		padding: 0 14px;
		border-radius: 6px;
		font-size: 14px;
		color: #999;
		cursor: pointer;
		user-select: none;
		margin-right: 30px;
		&:hover {
			color: #000;
			background: #f1f1f1;
		}
		&.active {
			color: #ff3f47;
			background: #f2dadf;
		}
	}
}
.course-group-list {
	// display: flex;
	// justify-content: space-between;
	// flex-wrap: wrap;
}
.page-left {
	display: flex;
	justify-content: flex-end;
}
.empty {
	height: 435px;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 169px;
		height: 216px;
		display: block;
	}
}
</style>
