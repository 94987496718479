<template>
  <div class="card" v-if="info" @click="cardClick">
    <div class="card-detail"  v-if="info.whetheHaveClassManager === 2">
      <div class="tag" v-if="info.hotLabel">
        <p class="tag-left"></p>
        <p class="tag-center">{{info.hotLabel}}</p>
        <p class="tag-right"></p>
      </div>
      <div class="fix-hei">
        <div class="title">{{info.courseGroupName}}</div>
        <p class="tip">{{info.subtitle}}</p>
        <p class="tip">
          <i class="el-icon-time"></i>
          {{info.classTime}}
        </p>
        <div class="teacher-list">
          <div class="teacher-item" v-for="(item, index) of info.teachers" :key="index">
            <img :src="item.headPortrait" :alt="item.teacherName">
            <p class="name">{{item.teacherName}}</p>
          </div>
        </div>
      </div>
      <div class="info-btn">
        <div class="ib-left">
          <div class="price" v-if='btnStateComputed.state === "立即报名"'>
            <span class="price-new">￥{{info.currentPrice}}</span>
            <span class="price-old" v-if="info.originalPrice">￥{{info.originalPrice}}</span>
          </div>
          <p class="ib-tip" v-if="info.costDescription">{{info.costDescription}}</p>
        </div>
        <div class="ib-right">
          <button class="buy-btn" :class="{'ib-btn': btnStateComputed.type === 0}" v-if="btnStateComputed.canClick">{{btnStateComputed.state}}</button>
          <button class="buy-btn buy-btn-gray" v-if="!btnStateComputed.canClick">{{btnStateComputed.state}}</button>
          <!-- <button class="ib-btn">立即购买</button> -->
        </div>
        <!-- <div class="ib-right" v-if="info.flag === 2">
          <button class="ib-btn">立即购买</button>
        </div>
        <div class="ib-right" v-if="info.flag === 1">
          
          <p class="tip">{{stateFilter(info)}}</p>
        </div> -->
      </div>
    </div>
    <div class="card-detail"  v-else>
      <div class="fix-hei">
        <div class="title">{{info.courseGroupName}}</div>
        <div class="class-list">
          <template v-for="(item, index) of info.flightManagerWebVos">
            <div class="class-item2"  :key="index">
              <img src="@/assets/images/courseGroup/icon1.png">
              <p class="name">{{item.name}}</p>
            </div>
          </template>
        </div>
        <div class="more" v-if="info.flightManagerWebVos"><p v-if="info.flightManagerWebVos.length > 4">......</p></div>
        <div class="more" v-else></div>
        <div class="teacher-list2" v-if="info.teachers">
          <div class="teacher-item" v-for="(item, index) of info.teachers" :key="index">
            <p class="name">{{item.teacherName}}</p>
          </div>
        </div>
      </div>
      <div class="info-btn">
        <div class="ib-left">
          <div class="price" v-if='btnStateComputed.state === "立即报名"'>
            <span class="price-new">￥{{info.currentPrice}}</span>
            <span class="price-old" v-if="info.originalPrice">￥{{info.originalPrice}}</span>
          </div>
          <p class="ib-tip" v-if="info.costDescription">{{info.costDescription}}</p>
        </div>
        <div class="ib-right">
          <button class="buy-btn" :class="{'ib-btn': btnStateComputed.type === 0}" v-if="btnStateComputed.canClick">{{btnStateComputed.state}}</button>
          <button class="buy-btn buy-btn-gray" v-if="!btnStateComputed.canClick">{{btnStateComputed.state}}</button>
          <!-- <button class="ib-btn">立即购买</button> -->
        </div>
        <!-- <div class="ib-right" v-if="info.flag === 2">
          <button class="ib-btn">立即购买</button>
        </div>
        <div class="ib-right" v-if="info.flag === 1">
          
          <p class="tip">{{stateFilter(info)}}</p>
        </div> -->
      </div>
    </div>
  </div>
  
</template>

<script>
import { BTNSTATEFILTER } from '@/config/config'
export default {
  name: "CourseGroupCard",
  props:{
    info: {
      type:Object,
      default:() => {
        return {}
      }
    }
  },
  methods: {
    cardClick() {
      this.$emit('click', this.info)
    }
  },
  computed: {
    btnStateComputed() {
      return BTNSTATEFILTER(this.info)
    }
  }
}
</script>

<style lang="less" scoped>
.card{
  width: 325px;
  height: 265px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  position: relative;
  padding: 40px 25px 20px 25px;
  margin:0 20px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover{
    transform: translateY(-5px);
  }
  .tag{
    position: absolute;
    min-width: 88px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    top:15px;
    left: 0;
    // background:;
    background-size: contain;
    user-select: none;
    display: flex;
    .tag-left{
      background: url('../../assets/images/courseGroup/tag-left.png') no-repeat top left;
      flex-grow: 0;
      flex-shrink: 0;
      width: 4px;
      height: 23px;
    }
    .tag-center{
      background: url('../../assets/images/courseGroup/tag-center.png') repeat top left;
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
    }
    .tag-right{
      background: url('../../assets/images/courseGroup/tag-right.png') no-repeat top right;
      flex-grow: 0;
      flex-shrink: 0;
      width: 9px;
      height: 20px;
    }
  }
  .fix-hei{
    height:195px;
    .title{
      height: 52px;
      line-height: 26px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 8px;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .class-list{
      width: 100%;
      overflow: hidden;
      height:70px;
      .class-item2{
        display: flex;
        align-items: center;
        width:50%;
        float: left;
        height:35px;
        line-height: 35px;
        img{
          display: block;
          width:18px;
          height:18px;
          margin-right: 5px;
        }
        .name{
          font-size: 14px;
          color:#3C7CFC;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
    }
    .more{
      height:28px;
      line-height: 28px;
      font-size: 16px;
    }
    .tip{
      font-size: 14px;
      height: 22px;
      line-height: 22px;
      font-weight: 400;
      color: #5B6B73;
      margin-bottom: 5px;
    }
    
  }
  
  .teacher-list{
    display: flex;
    height: 85px;
    // border-bottom: 1px solid #e8e8e8;
    .teacher-item{
      width: 68px;
      margin-right: 13px;
      img{
        display: block;
        width: 44px;
        height: 44px;
        overflow: hidden;
        margin-bottom: 8px;
        margin-left: 12px;
        border-radius: 50%;
      }
      .name{
        width: 28px;
        height: 15px;
        line-height: 15px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        width: 100%;
      }
    }
  }
  
  .teacher-list2{
    font-size: 14px;
    color:#666666;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    .teacher-item{
      line-height: 30px;
      .name{
        margin-right: 13px;
      }
    }
  }
  .info-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    border-top: 1px solid #f2f2f5;
    .ib-left{
      height:60px;
      .price{
        height:24px
      }
      .price-new{
        height: 18px;
        line-height: 18px;
        font-size: 22px;
        font-weight: bold;
        color: #FF3F47;
        line-height: 24px;
      }
      .price-old{
        height: 12px;
        line-height: 12px;
        font-size: 14px;
        font-weight: 400;
        text-decoration: line-through;
        color: #5B6B73;
      }
      .ib-tip{
        height: 15px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #5B6B73;
        margin-top: 8px;
        padding-right: 10px;
      }
    }
    .ib-right{
      .buy-btn{
        height: 34px;
        line-height: 29px;
        text-align: center;
        background: #FFFFFF;
        color: #FF3F47;
        font-size: 16px;
        font-weight: bold;
      }
      .ib-btn{
        width: 100px;
        height: 34px;
        line-height: 29px;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #FF3F47;
        color: #FF3F47;
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
      }
      .tip{
        height: 29px;
        line-height: 29px;
        text-align: center;
        color: #FF3F47;
      }
    }
    
  }
}
.fix-hei{
  // height: 125px;
}
</style>